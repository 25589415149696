@import '../../../../scss/theme-bootstrap';

.content-block-sitewide-banner {
  height: 0;
  opacity: 1;
  overflow: hidden;
  position: relative;
  transition: all;
  transform-origin: top center;
  .content-block-sitewide-banner--enabled & {
    display: flex;
    justify-content: #{$rdirection};
  }
  .site-header__fixed-wrapper & {
    max-width: 1281px;
    margin: 0 auto;
  }
  &__content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $color--white;
    position: fixed;
    z-index: 1;
    margin-top: 107px;
    transition: margin-top 0.5s;
    left: 50%;
    right: auto;
    transform: translate(-50%);
    @include breakpoint($bp--large-up) {
      width: 319px;
      left: auto;
      transform: none;
    }
    .content-block-sitewide-banner__trigger {
      .icon-remove {
        &::before {
          background: url('#{$base-theme-path}svg-icons/src/icon--close--white.svg') center center/cover no-repeat;
          @include breakpoint($bp--large-up) {
            background: url('#{$base-theme-path}svg-icons/src/icon--close-thick.svg') center center/cover no-repeat;
          }
        }
      }
    }
    .site-header-formatter__content-block-sitewide-banner & {
      @include breakpoint($bp--large-up) {
        margin-#{$rdirection}: 20px;
      }
    }
  }
  &__trigger {
    appearance: none;
    background: transparent;
    border-color: transparent;
    border-width: 1px;
    cursor: pointer;
    display: block;
    height: 20px;
    padding: 0;
    position: absolute;
    top: 18px;
    transform: translateY(-50%);
    width: 20px;
    z-index: 1;
    #{$rdirection}: 5px;
    .hide-trigger-btn & {
      display: none;
    }
    .icon-remove {
      display: block;
      position: relative;
      &::before {
        content: '';
        height: 15px;
        #{$rdirection}: 10px;
        position: absolute;
        top: 0;
        width: 15px;
      }
    }
  }
}
